import CustomEvents from './customEvents'
import Helpers from './helpers'

export default function MultiselectDropdown (options) {
  const helpers = new Helpers()
  const changedEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['multiselect-changed'])
  const multiselectDropdownReadyEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['multiselect-ready'])

  const config = {
    search: true,
    placeholder: 'Marque',
    txtSelected: 'Marques',
    txtAll: 'TOUTES LES MARQUES',
    txtRemove: 'Retirer',
    txtSearch: 'Marque',
    ...options
  }
  function newEl (tag, attrs) {
    const e = document.createElement(tag)
    if (attrs !== undefined) {
      Object.keys(attrs).forEach(k => {
        if (k === 'class') { Array.isArray(attrs[k]) ? attrs[k].forEach(o => o !== '' ? e.classList.add(o) : 0) : (attrs[k] !== '' ? e.classList.add(attrs[k]) : 0) } else if (k === 'style') {
          Object.keys(attrs[k]).forEach(ks => {
            e.style[ks] = attrs[k][ks]
          })
        } else if (k === 'text') { attrs[k] === '' ? e.innerHTML = '&nbsp;' : e.innerText = attrs[k] } else e[k] = attrs[k]
      })
    }
    return e
  }

  document.querySelectorAll('select#brand_name[multiple]').forEach((el, k) => {
    const div = newEl('div', { class: 'multiselect-dropdown', style: { padding: config.style?.padding ?? '' } })
    el.style.display = 'none'
    el.parentNode.insertBefore(div, el.nextSibling)
    const listWrap = newEl('div', { class: 'multiselect-dropdown-list-wrapper' })
    const list = newEl('div', { class: 'multiselect-dropdown-list' })
    const search = newEl('input', { class: ['multiselect-dropdown-search'].concat([config.searchInput?.class ?? 'form-control']), readonly: `${helpers.is_mobile() ? '' : 'readonly'}`, style: { width: '100%', display: el.attributes['multiselect-search']?.value === 'true' ? 'block' : 'none' }, placeholder: config.txtSearch })
    listWrap.appendChild(search)
    div.appendChild(listWrap)
    listWrap.appendChild(list)

    el.loadOptions = () => {
      list.innerHTML = ''

      if (el.attributes['multiselect-select-all']?.value == 'true') {
        const op = newEl('div', { class: 'multiselect-dropdown-all-selector' })
        const ic = newEl('input', { type: 'checkbox' })
        op.appendChild(ic)
        op.appendChild(newEl('label', { text: config.txtAll }))

        op.addEventListener('click', () => {
          op.classList.toggle('checked')
          op.querySelector('input').checked = !op.querySelector('input').checked

          const ch = op.querySelector('input').checked
          list.querySelectorAll(':scope > div:not(.multiselect-dropdown-all-selector)')
            .forEach(i => { if (i.style.display !== 'none') { i.querySelector('input').checked = ch; i.optEl.selected = ch } })

          el.dispatchEvent(new Event('change'))
        })
        ic.addEventListener('click', (ev) => {
          ic.checked = !ic.checked
        })
        el.addEventListener('change', (ev) => {
          const itms = Array.from(list.querySelectorAll(':scope > div:not(.multiselect-dropdown-all-selector)')).filter(e => e.style.display !== 'none')
          const existsNotSelected = itms.find(i => !i.querySelector('input').checked)
          if (ic.checked && existsNotSelected) ic.checked = false
          else if (ic.checked == false && existsNotSelected === undefined) ic.checked = true
        })

        list.appendChild(op)
      }

      Array.from(el.options).map(o => {
        const op = newEl('div', { class: o.selected ? 'checked' : '', optEl: o })
        const ic = newEl('input', { type: 'checkbox', checked: o.selected })
        op.appendChild(ic)
        op.appendChild(newEl('label', { text: o.text }))

        op.addEventListener('click', () => {
          op.classList.toggle('checked')
          op.querySelector('input').checked = !op.querySelector('input').checked
          op.optEl.selected = !op.optEl.selected
          el.dispatchEvent(new Event('change'))
        })
        ic.addEventListener('click', (ev) => {
          ic.checked = !ic.checked
        })
        o.listitemEl = op
        list.appendChild(op)
      })
      div.listEl = listWrap

      div.refresh = () => {
        div.querySelectorAll('span.optext, span.placeholder').forEach(t => div.removeChild(t))
        const sels = Array.from(el.selectedOptions)
        if (sels.length > (el.attributes['multiselect-max-items']?.value ?? 0)) {
          div.appendChild(newEl('span', { class: ['optext', 'maxselected', 'text-bold'], text: config.txtSelected + ' (' + sels.length + ')' }))
        } else {
          sels.map(x => {
            const c = newEl('span', { class: 'optext', text: x.text, srcOption: x })
            if ((el.attributes['multiselect-hide-x']?.value !== 'true')) {
              c.appendChild(newEl('span', {
                class: 'optdel',
                text: 'X',
                title: config.txtRemove,
                onclick: (ev) => {
                  c.srcOption.listitemEl.dispatchEvent(new Event('click')); div.refresh(); document.dispatchEvent(changedEvent); ev.stopPropagation()
                }
              }))
            }

            div.appendChild(c)
          })
        }
        if (el.selectedOptions.length == 0) div.appendChild(newEl('span', { class: 'placeholder', text: el.attributes.placeholder?.value ?? config.placeholder }))
      }
      div.refresh()
    }
    el.loadOptions()

    search.addEventListener('input', () => {
      list.querySelectorAll(':scope div:not(.multiselect-dropdown-all-selector)').forEach(d => {
        const txt = d.querySelector('label').innerText.toUpperCase()
        d.style.display = txt.includes(search.value.toUpperCase()) ? 'block' : 'none'
      })
    })

    if (helpers.is_mobile()) {
      search.addEventListener('touchstart', () => {
        search.removeAttribute('readonly')
      })
      search.addEventListener('focus', () => {
        search.removeAttribute('readonly')
      })
    }

    div.addEventListener('click', () => {
      div.listEl.style.display = 'block'
      search.focus()
      search.select()
    })

    if (!(el.attributes['multiselect-permanent']?.value == 'true')) {
      document.addEventListener('click', function (event) {
        if (!div.contains(event.target) && listWrap.style.display === 'block') {
          if (helpers.is_mobile()) {
            search.setAttribute('readonly', '')
          }
          listWrap.style.display = 'none'
          div.refresh()
          document.dispatchEvent(changedEvent)
          const payload = {detail: {element: el}}
          document.dispatchEvent(CustomEvents.getEvent(CustomEvents.EVENTS_KEY['multiselect-closed'], payload))
        }
      })
    }
  })

  document.dispatchEvent(multiselectDropdownReadyEvent)
}

let multiselectLoaded = false
window.addEventListener('load', () => {
  if (!multiselectLoaded) {
    MultiselectDropdown(window.MultiselectDropdownOptions)
    multiselectLoaded = true
  }
})

document.addEventListener('turbo:load', function () {
  if (!multiselectLoaded) {
    MultiselectDropdown(window.MultiselectDropdownOptions)
    multiselectLoaded = true
  }
})