function carMapboxInitialize(data) {
  mapboxgl.accessToken = data.token;

  const map = new mapboxgl.Map({
    container: 'car-detail-map',
    style: 'mapbox://styles/mapbox/streets-v12',
    center: data.center,
    zoom: 11,
    trackResize: true,
    dragRotate: false,
    touchZoomRotate: true
  });

  map.touchZoomRotate.disableRotation() // disable rotation, leaving pinch zoom in tact
  map.keyboard.disableRotation()
  map.addControl(new mapboxgl.NavigationControl({ showCompass: false }))
}
window.carMapboxInitialize = carMapboxInitialize