import CalculateActiveSearchFilters from './calculateActiveSearchFilters'
import Helpers from './helpers'
import CustomEvents from './customEvents'
import NouisliderManager from './manager/nouislider_manager'
import { Modal, Dropdown } from 'bootstrap'
import DesktopfiltersManager from './manager/desktopfilters_manager'
import PrefilterManager from './manager/prefilter_manager'
import SearchFormRequest from './searchFormRequest'
import SearchModalManager from "./manager/searchmodal_manager";
import SearchButtonManager from "./manager/searchbutton_manager";

const changeSearchFormEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['search-form-changed'])
const resetSearchFormEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['search-form-reset'])
const helpers = new Helpers()
const searchFormRequest = new SearchFormRequest()


let clickedDropdown

function seatsText (infValue, supValue) {
  let tooltip_text = ''
  if (supValue == 7) {
    tooltip_text = ' et plus'
  }
  let finalText = infValue + ' - ' + supValue + tooltip_text
  return finalText
}

function ageText (value, supValue) {
  let tooltip_text = ' ans'
  if (value == 35) {
    tooltip_text += ' et plus'
  }
  let finalText = value + tooltip_text
  return finalText
}

function licenceText (value, supValue) {
  let tooltip_text = ' ans'
  if (value == 10) {
    tooltip_text += ' et plus'
  }
  let finalText = value + tooltip_text
  return finalText
}

function defaultTooltipText (separator = ' - ') {
  return function (infValue, supValue) {
    return supValue == null ? infValue : infValue + separator + supValue
  }
}

function sliderDivConfiguration () {
  return {
    'dailyPriceSliderDiv': document.querySelector('#js-search_daily_price_slider'),
    'yearSliderDiv': document.querySelector('#js-search_year_slider'),
    'minAgeSliderDiv': document.querySelector('#js-search_driver_min_age_slider'),
    'licenceSliderDiv': document.querySelector('#js-search_driver_min_licence_slider'),
    'seatsSliderDiv': document.querySelector('#js-search_seats_slider')
  }
}

let yearSliderManager, seatsSliderManager, dailyPriceSliderManager, licenceSliderManager, minAgeSliderManager

function createNouiSliders () {
  const noUisliderChangedCallback = emitFiltersChangedEvent
  const sliderDivs = sliderDivConfiguration()
  const dailyPriceSliderDiv = sliderDivs['dailyPriceSliderDiv']
  if (!dailyPriceSliderDiv) {
    return
  }

  document.querySelectorAll('.noUi-base').forEach(function (element) {
    element.remove()
  })
  Object.entries(sliderDivs).forEach(([key, el]) => {el.noUiSlider ? el.noUiSlider.destroy() : null})

  dailyPriceSliderManager = new NouisliderManager(dailyPriceSliderDiv, helpers.formatTooltipPrice, noUisliderChangedCallback)

  const infDailyPriceInput = document.querySelector('#js-search_inf_daily_price')
  const supDailyPriceInput = document.querySelector('#js-search_sup_daily_price')
  dailyPriceSliderManager.initialize(dailyPriceSliderDiv.dataset, [infDailyPriceInput, supDailyPriceInput], defaultTooltipText('€ - '))

  const yearSliderDiv = sliderDivs['yearSliderDiv']
  yearSliderManager = new NouisliderManager(yearSliderDiv, helpers.formatDefaultTooltipMultipleValues, noUisliderChangedCallback)

  var infYearInput = document.querySelector('#js-search_min_year')
  var supYearInput = document.querySelector('#js-search_max_year')
  yearSliderManager.initialize(yearSliderDiv.dataset, [infYearInput, supYearInput], defaultTooltipText())

  const infSeatsInput = document.querySelector('#js-search_min_seats')
  const supSeatsInput = document.querySelector('#js-search_max_seats')
  const seatsSliderDiv = sliderDivs['seatsSliderDiv']
  seatsSliderManager = new NouisliderManager(seatsSliderDiv, helpers.formatDefaultTooltipMultipleValues, noUisliderChangedCallback)
  seatsSliderManager.initialize(seatsSliderDiv.dataset, [infSeatsInput, supSeatsInput], seatsText)

  const licenceSliderDiv = sliderDivs['licenceSliderDiv']
  licenceSliderManager = new NouisliderManager(licenceSliderDiv, helpers.formatDefaultTooltipMultipleValues, noUisliderChangedCallback)
  let licenceInput = document.querySelector('#js-search_driver_min_licence')
  licenceSliderManager.initialize(licenceSliderDiv.dataset, [licenceInput], licenceText)

  const minAgeSliderDiv = sliderDivs['minAgeSliderDiv']
  minAgeSliderManager = new NouisliderManager(minAgeSliderDiv, helpers.formatDefaultTooltipMultipleValues, noUisliderChangedCallback)
  const minAgeInput = document.querySelector('#js-search_driver_min_age')
  minAgeSliderManager.initialize(minAgeSliderDiv.dataset, [minAgeInput], ageText)

}

function resetSliderManagers (sliderManagersArray) {
  sliderManagersArray.forEach(sliderManager => sliderManager.reset())
}


function emitFiltersChangedEvent () {
  const service = new CalculateActiveSearchFilters()
  const nb = service.process()
  const payload = { detail: { nb } }
  document.dispatchEvent(CustomEvents.getEvent(CustomEvents.EVENTS_KEY['filters-changed'], payload))
}

function resetSearchForm (e) {
  e.preventDefault()
  resetSearchFormDom()
  document.dispatchEvent(changeSearchFormEvent)
  document.dispatchEvent(resetSearchFormEvent)
  submitFilterSearch('#js-filter_search_panel form')
}

function resetSearchFormDom () {
  const search_checkboxes = document.querySelectorAll('.js-filter-search-checkbox')
  search_checkboxes.forEach(function (checkbox) {
    checkbox.checked = false
  })
  document.querySelector('#force_brand_model').value = ''
  document.querySelector('.filter-search-brand').selectedIndex = -1

  resetSliderManagers([yearSliderManager, seatsSliderManager, dailyPriceSliderManager, licenceSliderManager, minAgeSliderManager])
}




function interceptSubmitButton () {
  document.querySelector('#js-header-submit-input').addEventListener('click', function (e) {
    e.preventDefault()
    const changeDatesModal = document.querySelector('#changeDatesModal')
    if (changeDatesModal) {
      const modal = Modal.getInstance(changeDatesModal)
      if (modal) {
        modal.hide()
      }
    }
    submitFilterSearch('#home-search')
  })
}

function preventBehaviours () {
  // Prevents modal from closing on click
  const form = document.querySelector('#js-filter_search_panel')
  if(!form){
    return;
  }
  const dropdowns = form.querySelectorAll('.dropdown-menu')
  dropdowns.forEach(function (dropdown) {
    dropdown.addEventListener('click', (e) => {
      e.stopPropagation()
    })
  })

  // Management Of Location Input
  const locationInput = document.querySelector('#js-search_location_autocomplete')

  locationInput.addEventListener('change', function () {
    document.querySelector('#js-search_radius').value = ''
    if (!helpers.is_mobile()) {
      submitFilterSearch('#home-search')
    }
  })
}

function handlePrefilterChange (e) {
  let motor, body_styles, min_year, max_year, inf_daily_price;
  ({ motor, body_styles, min_year, max_year, inf_daily_price } = e.detail.filters)
  resetSearchFormDom()
  if (motor) {
    document.querySelector(`input[name=motor][value=${motor}]`).checked = true
  }
  if (body_styles) {
    body_styles.forEach(el =>
      document.querySelector(`input[name="body_styles[]"][value=${el}]`).checked = true)
  }
  if (min_year) {
    yearSliderManager.setMin(min_year)
  }
  if (max_year) {
    yearSliderManager.setMax(max_year)
  }
  if (inf_daily_price) {
    dailyPriceSliderManager.setMin(inf_daily_price)
  }

  emitFiltersChangedEvent()
  submitFilterSearch('#js-filter_search_panel form')
}

function handleSearchReinit () {
  var isReinitSearchPresent = document.querySelectorAll('#js-reinit_search').length > 0
  if (!isReinitSearchPresent) {
    return
  }
  reinitSearch = document.querySelector('#js-reinit_search')
  reinitSearch.addEventListener('click', function (e) {
    // Context of re-initialisation
    var search_context = reinitSearch.dataset.search_context
    var search_daily_price_slider = document.querySelector('#js-search_daily_price_slider')
    // Min Max Values
    var minDailyPrice = search_daily_price_slider.dataset.min_daily_price || 0
    var maxDailyPrice = search_daily_price_slider.dataset.max_daily_price || 1000
    // Input Reference
    var locationInput = document.querySelector('.js-search_location_autocomplete')
    //var locationDisplayInput = document.querySelector('#js-search_location_display_autocomplete')
    var infDailyPriceInput = document.querySelector('#js-search_inf_daily_price')
    var supDailyPriceInput = document.querySelector('#js-search_sup_daily_price')
    var brandModelInput = document.querySelector('#js-search_brand_name')
    // Discussion on search_context
    switch (search_context) {
      case 'no_cars_at_that_price':
        resetSearchForm(e)
        break
      case 'no_cars_in_the_area' :
        locationInput.value = ''
        //locationDisplayInput.value = ''
        break
      case 'no_cars_at_that_price_in_the_area' :
        resetSearchForm(e)
        break
      case 'no_cars_in_the_area_with_this_brand_model' :
        resetSearchForm(e)
        break
      case 'no_cars_at_that_price_in_the_area_with_this_brand_model' :
        resetSearchForm(e)
        break
      default :
        infDailyPriceInput.value = minDailyPrice
        supDailyPriceInput.value = maxDailyPrice
        search_daily_price_slider.noUiSlider.set([minDailyPrice, maxDailyPrice])
        locationInput.value = ''
        //locationDisplayInput.value = ''
        brandModelInput.value = ''
        break
    }
    document.dispatchEvent(changeSearchFormEvent)
    submitFilterSearch('#js-filter_search_panel form')
  })

}

function initializeSearchEngine () {

  emitFiltersChangedEvent()
  interceptSubmitButton()
}

function closeDropdown () {
  if (clickedDropdown) {
    const bs_dropdown = Dropdown.getInstance(clickedDropdown)
    bs_dropdown.hide()
  }
}

function submitFilterSearch (form_id) {
  const callback = function () {
    const modal = Modal.getInstance(document.getElementById('searchModal'))
    if (modal) {
      modal.hide()
    }
    closeDropdown()
    document.dispatchEvent(CustomEvents.getEvent(CustomEvents.EVENTS_KEY['search-form-submitted']));
  }
  searchFormRequest.submitFilterSearch(form_id, callback)
}

if (document.querySelector('#change_dates_form') && !helpers.is_mobile()) {

  document.addEventListener(CustomEvents.EVENTS_KEY['search-form-input-changed'], function (e) {
    const starts_on = document.querySelector('#search_booking_starts_on').value
    const ends_on = document.querySelector('#search_booking_ends_on').value
    if (!starts_on && !ends_on || starts_on && ends_on) {
      submitFilterSearch('#home-search')
    }
  })
}

if (!helpers.is_mobile()) {
  document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-closed'], function () {
    submitFilterSearch('#js-filter_search_panel form')
  })
}


document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-ready'], function () {
  createNouiSliders()
  preventBehaviours()
  initializeSearchEngine()
  brandButtonInitialize()
  priceButtonInitialize()
})

document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-changed'], function () {
  emitFiltersChangedEvent()
})


let reinitSearch = document.querySelector('#js-reinit_search')
if (reinitSearch) {
  handleSearchReinit()
}

document.addEventListener(CustomEvents.EVENTS_KEY['no-car'], function () {
  handleSearchReinit()
})

document.addEventListener(CustomEvents.EVENTS_KEY['prefilter-changed'], function (e) {
  handlePrefilterChange(e)
})

const prefilters = document.querySelector('#js-prefilters')
if (prefilters) {
  new PrefilterManager()
}

const desktopForm = document.querySelector('#js-desktop-car-items-form')
if (desktopForm) {
  new DesktopfiltersManager()
}

const showFilterFormButton = document.querySelector('#js-filter-general-button')
if (showFilterFormButton) {
    new SearchModalManager(showFilterFormButton)
}

const showFilterPriceFormButton = document.querySelector('#js-filter-price-button')
if (showFilterPriceFormButton) {
    new SearchModalManager(showFilterPriceFormButton)
}

const showFilterBrandFormButton = document.querySelector('#js-filter-brand-button')
if (showFilterBrandFormButton) {
    new SearchModalManager(showFilterBrandFormButton)
}

const showFilterByFormButton = document.querySelector('#js-filter-by-button')
if (showFilterByFormButton) {
    new SearchModalManager(showFilterByFormButton)
}

const searchCheckboxFilters = document.querySelectorAll('.js-filter-search-checkbox')
if (searchCheckboxFilters.length > 0) {

  searchCheckboxFilters.forEach(function (checkbox) {
    checkbox.addEventListener('click', function () {
      clickOnFilter(this)
    })
  })

  const resetSearchFormSelector = document.querySelector('#js-reset-search-form')
  if (resetSearchFormSelector) {
    resetSearchFormSelector.addEventListener('click', resetSearchForm)
  }

  const resetOptionsSelector = document.querySelector('#resetOptions')
  if (resetOptionsSelector) {
    resetOptionsSelector.addEventListener('click', e => resetDropdown(e,
      '#js-filtersDropdownOptions-container',
      [licenceSliderManager, minAgeSliderManager]
    ))
  }

  const resetFeaturesSelector = document.querySelector('#resetFeatures')
  if (resetFeaturesSelector) {
    resetFeaturesSelector.addEventListener('click', e =>
      resetDropdown(e, '#js-filtersDropdownFeatures-container',
        [yearSliderManager, seatsSliderManager]))
  }

  const resetPriceSelector = document.querySelector('#resetPrice')
  if (resetPriceSelector) {
    resetPriceSelector.addEventListener('click', e => resetDropdown(e, null, [dailyPriceSliderManager]))
  }

  document.querySelector('#force_brand_model').addEventListener('change', function () {
    clickOnFilter()
  })

  const select_selector = document.querySelector('.filter-search-select')
  if (select_selector) {
    select_selector.addEventListener('change', function () {
      changeSelect(this)
    })

    function changeSelect (el) {
      let name = el.name
      document.querySelector('select[name=\'' + name + '\']').value = el.value
      emitFiltersChangedEvent()
    }
  }



  function clickOnFilter (el) {
    if (el && el.name === 'body_styles[]') {
      const inputElements = Array.from(document.getElementsByName('body_styles[]')).filter((element) => element.type === 'checkbox')
      ensureBodyStylesConsistency(inputElements, el)
    }
    emitFiltersChangedEvent()
  }

  function ensureBodyStylesConsistency (inputElements, changedElement) {

    let all_element = inputElements.find((element) => element.value === 'tout')
    const other_elements = inputElements.filter((element) => element.value !== 'tout')
    if (changedElement.value === 'tout' && changedElement.checked) {
      for (let other_element of other_elements) {
        other_element.checked = false
      }
    }
    if (changedElement.value !== 'tout' && changedElement.checked) {
      all_element.checked = false
    }
  }

  function resetDropdown(e, id = null, sliders = [])
  {
    e.preventDefault()

    if (id) {
      const dropdown = document.querySelector(id)
      resetDropdownCheckboxes(dropdown)
    }
    if(id === '#js-filtersDropdownFeatures-container'){
      document.querySelector('#force_brand_model').value = ''
    }
    resetSliderManagers(sliders)

    emitFiltersChangedEvent()
    submitFilterSearch('#js-filter_search_panel form')
  }

  function resetDropdownCheckboxes (dropdown) {
    const search_checkboxes = dropdown.querySelectorAll('.js-filter-search-checkbox, .checked')
    search_checkboxes.forEach(checkbox => checkbox.checked = false)
  }


}


const submitSearchFormSelector =
  document.querySelectorAll('.submitSearchForm')
if (submitSearchFormSelector) {
  submitSearchFormSelector.forEach(function (button) {
    button.addEventListener('click', function (event) {
      event.preventDefault()
      document.dispatchEvent(changeSearchFormEvent)
      const dropdownMenu = event.target.closest('.dropdown-menu')
      if (dropdownMenu) {
        clickedDropdown = dropdownMenu.previousSibling
      }
      submitFilterSearch('#js-filter_search_panel form')
    })
  })
}

function priceButtonInitialize() {
  const priceButton = document.querySelector("#js-filter-price-button")
  if (priceButton) {
    new SearchButtonManager(priceButton)
  }
}

function brandButtonInitialize() {
  const brandButton = document.querySelector("#js-filter-brand-button")
  if (brandButton) {
      new SearchButtonManager(brandButton)
  }
}

const nb_button_div = document.querySelector('#js-filters-nb')
if (nb_button_div) {
  document.addEventListener(CustomEvents.EVENTS_KEY['filters-changed'], function (e) {
      calculateActiveSearchFilters(e.detail.nb)
    }
  )
  document.addEventListener(CustomEvents.EVENTS_KEY['search-form-reset'], function () {
    calculateActiveSearchFilters(0)
  })
  document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-closed'], function () {
    emitFiltersChangedEvent()
  })

  function calculateActiveSearchFilters (nb) {
    if (nb === 0) {
      nb_button_div.classList.add('d-none')
    } else {
      nb_button_div.classList.remove('d-none')
      nb_button_div.innerHTML = nb
    }
  }

}



const brandModelInput = document.querySelector('#js-search_brand_model_home')
if (brandModelInput) {
  brandModelInput.addEventListener('change', function (event) {
    handleBrandModelChange(event)
  })
  brandModelInput.addEventListener('keydown', function (event) {
    handleBrandModelChange(event)
  })

  function handleBrandModelChange (event) {
    if (event.type === 'change' && event.isTrigger) {
      submitFilterSearch('#home-search')
    } else {
      if (event.keyCode === 13) {
        submitFilterSearch('#home-search')
      }
    }
  }
}

document.addEventListener(CustomEvents.EVENTS_KEY['search-form-changed'], function (e) {
    if (e.detail && e.detail.element.classList.contains("js-filter-by-radio")) {
        submitFilterSearch('#js-filter_search_panel form')
    }
})


const submitSearchFormButton = document.querySelector('#js-submitSearchForm')
if (submitSearchFormButton) {

  document.addEventListener(CustomEvents.EVENTS_KEY['filters-changed'], function () {
    submitFilterSearchAndGetCount()
  })
  document.addEventListener(CustomEvents.EVENTS_KEY['search-form-reset'], function () {
    submitFilterSearchAndGetCount()
  })

  function submitFilterSearchAndGetCount () {
    const url = "/cars_results_count"
    const callback = function (value) {updateSubmitSearchFormValue(value)}
    searchFormRequest.submitFilterSearchAndGetCount('#js-filter_search_panel form', url, callback)
  }

  function updateSubmitSearchFormValue (value) {
    submitSearchFormButton.innerHTML = value
  }
}





let selectElement = document.querySelector('#js-custom_order_by')
if (selectElement) {
  handleOrderBySelection()

  function handleOrderBySelection () {
    let jsOrderByInput = document.querySelector('#js-search_order_by')
    selectElement.addEventListener('change', function () {

      jsOrderByInput.value = selectElement.value
      submitFilterSearch('#home-search')

    })

  }

  document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-ready'], function () {

    selectElement.addEventListener('focus', () => {
      Array.from(selectElement.children).filter(child => child.getAttribute('value') === '')
        .forEach(child => child.remove())
    })

  })

}
