import Helpers from './helpers'
import CustomEvents from './customEvents'

const noCarEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['no-car'])

function mapboxInitialize ({ cars, mapbox_token, superhost_path, bookmark_icon_url }) {
  let mapCenter, map
  let markers = []

  window.addEventListener('popstate', function (event) {
    if (event.state) {
      window.location.reload()
    }
  })
  let mapOpened = false

  const bookmark_path = '/bookmarks/add'
  const helpers = new Helpers()

  mapboxgl.accessToken = mapbox_token

  const totalPriceKey = 'total_price'

  addEventListener('turbo:before-stream-render', (event) => {
    const fallbackToDefaultActions = event.detail.render

    event.detail.render = function (streamElement) {
      if (streamElement.action === 'update_map') {
        const cars = streamElement.getAttribute('message')
        const carsJson = JSON.parse(cars)
        carsDiv = document.querySelector('#js-cars-items-container')
        updateMapWithCars(carsJson)
        if (carsJson.length === 0) {
          if (mobileCloseMapButton) {
            closeMobileMap()
          }
          document.dispatchEvent(noCarEvent)
        } else {
          nextPageLink = document.querySelector('#js-next-page')
          if (mapOpened) {
            openMobileMap()
          }
        }
      } else {

        fallbackToDefaultActions(streamElement)
        if (streamElement.action === 'update') {
          document.dispatchEvent(
            CustomEvents.getEvent(CustomEvents.EVENTS_KEY['turbo-refresh'],
              { detail: { target: streamElement.target } }
            )
          )
        }
      }
    }
  })

  const mapHTML = document.querySelector('#map')
  const mapSwitch = document.querySelector('#js-map-switch')
  const fixedModal = document.querySelector('#js-fixed-modal')
  const bodyResultsContainer = document.querySelector('.car-items-results-body')
  if (mapSwitch) {
    mapSwitch.removeAttribute('disabled')
    if (localStorage.getItem('mapboxSwitch') === 'open') {
      openDesktopMap()
    }
    mapSwitch.addEventListener('change', setMapVisibility)
  }

  const mobileOpenMapButton = document.querySelector('#js-show-mapbox')
  const mobileCloseMapButton = document.querySelector('#js-hide-mapbox')
  const mobileMoreButton = document.querySelector('#js-others-mapbox')
  let nextPageLink = document.querySelector('#js-next-page')
  let carsDiv = document.querySelector('#js-cars-items-container')

  if (mobileOpenMapButton) {
    mobileOpenMapButton.addEventListener('click', () => {
      openMobileMap()
    })
    mobileMoreButton.addEventListener('click', () => {
      requestMap(nextPageLink.getAttribute('href'))
    })
    mobileCloseMapButton.addEventListener('click', () => {
      closeMobileMap()
    })
  }

  const centerButton = document.querySelector('#js-mapbox-search')

  if(centerButton) {
    mapHTML.addEventListener('mouseup', () => {
      centerButton.classList.remove('d-none')
    })
    mapHTML.addEventListener('touchend', () => {
      centerButton.classList.remove('d-none')
      fixedModal.classList.add('d-none')
    })

    centerButton.addEventListener('click', () => {
      const url = new URL(window.location.href)
      mapCenter = map.getCenter()
      const mapCenterArray = [mapCenter.lat, mapCenter.lng]
      const newLocation = ''
      const newLocationGeocoded = mapCenterArray.join(',')
      url.searchParams.set('location_geocoded', newLocationGeocoded)
      url.searchParams.set('location', newLocation)
      url.searchParams.set('page', 1)
      document.querySelector('#js-search_location_autocomplete').value = newLocation
      document.querySelector('#js-search_location_geocoded').value = newLocationGeocoded
      const newUrl = url.href
      requestMap(newUrl)
    })
  }

  function requestMap (url) {
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
      }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  function initializeMap(){
    if(map){
      return;
    }
    map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      attributionControl: false,
      trackResize: true,
      dragRotate: false,
      logoPosition: 'top-right',
      touchZoomRotate: true // true to enable pinch zoom, rotation disabled later
    })

    updateMapWithCars(cars)

    map.touchZoomRotate.disableRotation() // disable rotation, leaving pinch zoom in tact
    map.keyboard.disableRotation()
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-left')
    map.addControl(new mapboxgl.AttributionControl({
      compact: true
    }))
  }

  function updateMapWithCars (cars) {

    const cars_geojson = generateGeojson(cars)
    let center = [2.21, 46.23]
    let zoom = 5
    if (cars.length > 0) {
      const coords = coordsArray(cars_geojson)
      const coordsLongitude = coords.map(coordinates => coordinates[0])
      const coordsLatitude = coords.map(coordinates => coordinates[1])
      zoom = calculateZoom(coordsLongitude, coordsLatitude)
      center = calculateGeographicCenter(coords)
    }
    if (markers.length > 0) {
      markers.forEach(marker => marker.remove())
    }
    markers = generateMarkers(cars_geojson)
    markers.forEach(marker => { marker.addTo(map) })

    map.setZoom(zoom)
    map.setCenter(center)
  }

  function closeMobileMap () {
    mapOpened = false
    mapHTML.classList.add('d-none')
    fixedModal.classList.add('d-none')
    mobileCloseMapButton.classList.add('d-none')
    mobileMoreButton.classList.add('d-none')
    mobileOpenMapButton.classList.remove('d-none')
    carsDiv.classList.remove('d-none')
    localStorage.setItem('mapbox', 'close')
  }

  function openMobileMap () {
    initializeMap()
    mapOpened = true
    mapHTML.classList.remove('d-none')
    mobileCloseMapButton.classList.remove('d-none')
    if (nextPageLink) {
      mobileMoreButton.classList.remove('d-none')
    } else {
      mobileMoreButton.classList.add('d-none')
    }
    mobileOpenMapButton.classList.add('d-none')
    carsDiv.classList.add('d-none')
    map.resize()
    localStorage.setItem('mapbox', 'open')
  }

  function openDesktopMap () {
    initializeMap()
    mapSwitch.setAttribute('checked', 'checked')
    mapHTML.classList.remove('d-none')
    bodyResultsContainer.classList.add('car-items-results-body-with-map')
    map.resize()
    localStorage.setItem('mapboxSwitch', 'open')
  }

  function setMapVisibility () {
    if (this.checked) {
      openDesktopMap()
    } else {
      mapHTML.classList.add('d-none')
      bodyResultsContainer.classList.remove('car-items-results-body-with-map')
      localStorage.setItem('mapboxSwitch', 'closed')
    }
  }

  function mapCarToGeoJson (car) {
    const payloadProperties = {
      title: car.title,
      id: car.id,
      alt: car.alt,
      brand: car.brand,
      city: car.city,
      bookmark_available: car.bookmark_available,
      picture: car.picture,
      price_per_day: car.price_per_day,
      reviews: car.reviews,
      superhost: car.superhost,
      url: car.url
    }
    if (car.hasOwnProperty(totalPriceKey)) {
      payloadProperties[totalPriceKey] = car[totalPriceKey]
    }
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [car.longitude, car.latitude]
      },
      properties: payloadProperties
    }
  }

  function generateGeojson (cars) {
    return cars.map(mapCarToGeoJson)
  }

  function mapFeatureToMarker (feature) {
    // create a HTML element for each feature
    const el = customMarkers(feature.properties.price_per_day)
    const html_code = popupHTML(feature.properties)

    const marker = new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates)

    if (helpers.is_mobile()) {
      marker.getElement().addEventListener('click', () => {
        fixedModal.classList.remove('d-none')
        fixedModal.innerHTML = html_code
      })
    } else {
      const popup = new mapboxgl.Popup({ offset: 25 })
        .setHTML(html_code)
      marker.setPopup(popup)
    }

    return marker
  }

  function generateMarkers (geoJsonFeatures) {
    return geoJsonFeatures.map(mapFeatureToMarker)
  }

  function customMarkers (price) {
    const el = document.createElement('div')
    el.className = 'mapbox-marker'
    const text = document.createElement('span')
    text.innerHTML = price + ' €'
    el.appendChild(text)
    return el
  }

  function popupHTML (properties) {
    let finalBlock = ''
    if (properties.bookmark_available) {
      finalBlock += `
        <span class="car-item-bookmark add_bookmark_car_${properties.id}">
          <a data-turbo="true" rel="nofollow" data-turbo-method="post" href="${bookmark_path}?car_id=${properties.id}">
            <img alt="${properties.title}" height="28px" width="28px" class="car-item-bookmark-icon" src="${bookmark_icon_url}">
          </a>
        </span>`
    }
    finalBlock += `<a href="${properties.url}" class="mapbox-fixed-modal-link">
                      <img src="${properties.picture}" 
                      alt="location-${properties.brand}-${properties.city}-roadstr" 
                      title="${properties.title}" 
                      class="car-item-mapbox-image">
                      <div class="car-item-mapbox">
                          <h3 class="car-item-name-mapbox">${properties.title}</h3>
                          <div class="d-flex">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="blue-star-svg">
                                  <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                              </svg>
                              <span class="car-item-review-text">${properties.reviews}</span>`

    if (properties.superhost) {
      finalBlock += superhostHTML(properties.alt)
    }
    finalBlock += '</div>'

    let titleBlock = `<h4 class="car-item-price-mapbox"><span><span class="car-item-bold">${properties.price_per_day}€</span> par jour</span>`

    if (totalPriceKey in properties) {
      titleBlock += totalPriceHTML(properties[totalPriceKey])
    }
    titleBlock += '</h4>'

    return finalBlock += titleBlock + '</div>' + '</a>'
  }

  function totalPriceHTML (total_price_value) {
    return `<span class="car-item-dot"></span>
                <span class="car-item-grey">
                    <span class="car-item-bold">${total_price_value}€</span> au total
                </span>`
  }

  function superhostHTML (alt) {
    const superhostIcon = document.createElement('img')
    superhostIcon.setAttribute('src', superhost_path)
    superhostIcon.setAttribute('alt', alt)
    superhostIcon.className = 'car-item-mapbox-superhost'

    return superhostIcon.outerHTML
  }

  function toRadians (degrees) {
    return degrees * Math.PI / 180
  }

  function toDegrees (radians) {
    return radians * 180 / Math.PI
  }

  function calculateGeographicCenter (coords) {
    if (coords.length === 0) {
      return null
    }

    let x = 0
    let y = 0
    let z = 0

    coords.forEach(([lat, lon]) => {
      const latRad = toRadians(lat)
      const lonRad = toRadians(lon)

      x += Math.cos(latRad) * Math.cos(lonRad)
      y += Math.cos(latRad) * Math.sin(lonRad)
      z += Math.sin(latRad)
    })

    const total = coords.length

    x /= total
    y /= total
    z /= total

    const centralLon = Math.atan2(y, x)
    const hyp = Math.sqrt(x * x + y * y)
    const centralLat = Math.atan2(z, hyp)

    return [toDegrees(centralLat), toDegrees(centralLon)]
  }

  function calculateZoom (coordsLongitude, coordsLatitude) {
    const maxLongitude = returnMaxValue(coordsLongitude)
    const minLongitude = returnMinValue(coordsLongitude)
    const maxLatitude = returnMaxValue(coordsLatitude)
    const minLatitude = returnMinValue(coordsLatitude)
    const longitudeRange = maxLongitude - minLongitude
    const latitudeRange = maxLatitude - minLatitude
    let maxRange = latitudeRange

    if (longitudeRange > latitudeRange) {
      maxRange = longitudeRange
    }
    let zoom = maxRangeZoom(maxRange)
    if (helpers.is_mobile()) {
      zoom -= 1
    }
    return zoom
  }

  function returnMaxValue (array) {
    return array.reduce((accumulator, currentValue) => {
      return Math.max(accumulator, currentValue)
    }, -Infinity)
  }

  function returnMinValue (array) {
    return array.reduce((accumulator, currentValue) => {
      return Math.min(accumulator, currentValue)
    }, Infinity)
  }

  function maxRangeZoom (maxRange) {
    // https://wiki.openstreetmap.org/wiki/Zoom_levels for zoom levels in terms of ranges
    if (maxRange > 5.625) {
      return 5
    } else if (maxRange > 2.813) {
      return 6
    } else if (maxRange > 1.406) {
      return 7
    } else if (maxRange > 0.703) {
      return 8
    } else if (maxRange > 0.352) {
      return 9
    } else if (maxRange > 0.176) {
      return 10
    } else if (maxRange > 0.088) {
      return 11
    } else if (maxRange > 0.044) {
      return 12
    } else if (maxRange > 0.022) {
      return 13
    } else {
      return 14
    }
  }

  function coordsArray (cars) {
    const coords = []
    for (const car of cars) {
      coords.push(car.geometry.coordinates)
    }

    return coords
  }
}



window.mapboxInitialize = mapboxInitialize
