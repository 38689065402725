import { Modal } from 'bootstrap'
import CustomEvents from "../customEvents";

export default class SearchModalManager {
    constructor(showFormButton) {
        const buttonId = showFormButton.id;
        const formId = this.formId(buttonId)
        showFormButton.addEventListener('click', () => this.formManager(formId))

        if (formId == "js-filter-brand") {
            document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-ready'], function () {
                const multiselectBlock = document.querySelector("#" + formId)
                const multiselect = multiselectBlock.querySelector(".multiselect-dropdown-list-wrapper")
                multiselect.style.display = "block"
            })
        }

        const filterByRadios = document.querySelectorAll(".js-filter-by-radio")
        if (filterByRadios.length > 0) {
            filterByRadios.forEach(function (radio) {
                radio.addEventListener("change", () => {
                    const payload = { detail: { element: radio } }
                    document.dispatchEvent(CustomEvents.getEvent(CustomEvents.EVENTS_KEY['search-form-changed'], payload))
                })
            })
        }
    }

    formManager(formId) {
        this.formHeader(formId)
        this.formContent(formId)
        this.formFooter(formId)
        this.showForm()
    }

    formId(buttonId) {
        return buttonId.replace('-button', '');
    }

    showForm() {
        const searchModal = document.querySelector('#searchModal')
        if (searchModal) {
            const modal = Modal.getOrCreateInstance(searchModal)
            if (modal) {
                modal.show()
            }
        }
    }

    formContent (formId) {
        const formsArray = document.querySelectorAll(".js-filter");
        formsArray.forEach(form => form.classList.add("d-none"))

        const form = document.querySelector("#" + formId);
        form.classList.remove("d-none");
    }

    formHeader(formId) {
        const closeCross = document.querySelector("#js-filters-close-cross");
        const closeArrow = document.querySelector("#js-filters-close-arrow");
        const modal = document.querySelector("#js-mobile-modal")
        if (formId == "js-filter-general") {
            closeCross.classList.add("d-none");
            closeArrow.classList.remove("d-none");
            modal.classList.add("rounded-0")
        } else {
            closeCross.classList.remove("d-none");
            closeArrow.classList.add("d-none");
            modal.classList.remove("rounded-0")
        }

        const title = document.querySelector("#js-filters-title");
        if (formId == "js-filter-general") {
            title.innerHTML = "Vos filtres"
        } else if (formId == "js-filter-by") {
            title.innerHTML = "Trier la recherche"
        } else if (formId == "js-filter-price") {
            title.innerHTML = "Définir un prix"
        } else if (formId == "js-filter-brand") {
            title.innerHTML = "Choisir des marques"
        }
    }

    formFooter(formId) {

        const eraseButton = document.querySelector("#js-reset-search-form");
        const searchButton = document.querySelector("#js-submitSearchForm");
        const classListArray = ["d-none", "w-100"];
        eraseButton.classList.remove(...classListArray);
        searchButton.classList.remove(...classListArray);
        let eraseButtonClass = "";
        let searchButtonClass = "";
        if (formId == "js-filter-by") {
            eraseButtonClass = "d-none"
            searchButtonClass = "d-none"
        } else if (formId == "js-filter-brand") {
            eraseButtonClass = "d-none"
            searchButtonClass = "w-100"
        }

        if (eraseButtonClass) {
            eraseButton.classList.add(eraseButtonClass);
        }
        if (searchButtonClass) {
            searchButton.classList.add(searchButtonClass);
        }
        // @quickfix
        if(formId == "js-filter-brand"){
            searchButton.innerHTML = "Voir les résultats"
        }
    }
}